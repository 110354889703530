/* General styling */

.bubble {
  background-color: #414141;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.1;
  color: #e5e5e5;
  padding: 20px 24px;
  position: absolute;
  z-index: 1;
}

.bubble-wrapper {
  top: 37px;
  z-index: 1;
}
.bubble-helper {
  position: absolute;
  left: -357px;
  right: 0;
  top: 29px;
}
.bubble-helper-inner {
  display: block;
}
.helper {
  position: relative;
  display: inline-block;
}
.helper-text {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
/* .bubble-inner {
    max-width: 452px;
} */
.text-wrapper {
  text-align: left;
}
.text-wrapper span {
  margin-top: 10px;
  max-width: 452px;
  display: inline-block;
}
.text-wrapper span:nth-child(1) {
  margin-top: 0;
}

/* Dialog */

.bubble-pipeSizeIn,
.bubble-pipeSizeMm {
  top: 112%;
  right: 0;
  left: -48%;
}
.dialog-warning {
  text-align: right;
  display: block;
  color: red;
  font-size: 12px;
}

/* Dialog Arrow */

.dialog-bubble::before,
.dialog-bubble::after {
  content: '';
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  border-style: solid;
  border-color: transparent;
}
.dialog-bubble::before {
  border-bottom-color: #414141;
  border-width: 8px;
  right: 33px;
}

/* Pipe Type */

.shape-wrapper {
  display: flex;
}
.pipe-example {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}
.pipe-example img {
  max-width: 100%;
}
.pipe-example:nth-child(1) {
  margin-right: 30px;
}
.pipe-example span {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: rgba(33, 33, 33, 0.8);
}

/* Helper Arrow direction */
.bubble-helper-inner::before {
  content: '';
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #414141;
  border-width: 8px;
  right: 34px;
}

/* Transitions */
.tool-appear {
  opacity: 0;
}
.tool-appear.tool-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.tool-exit {
  opacity: 1;
}
.tool-exit.tool-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
  position: absolute;
}
.tool-exit-done {
  opacity: 0;
}
.tool-enter {
  opacity: 0;
}
.tool-enter.tool-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
  position: absolute;
}
.tool-enter-done {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .helper {
    position: static;
  }
  .bubble-helper {
    margin: 7px 16px 0;
  }
  .bubble-inner {
    max-height: 250px;
    overflow: auto;
  }
  .text-wrapper {
    margin-right: 15px;
  }
  .helper {
    margin-bottom: 10px;
  }
  .bubble-pipeSizeIn {
    left: 0;
  }
  .bubble-pipeSizeMm {
    left: 0;
    right: 0;
  }
  .bubble-pipeSizeMm::before {
    right: unset;
    left: 38px;
  }
  .bubble-pipeSizeMm::after {
    left: 37px;
    right: unset;
  }
  .wrapper-right,
  .wrapper-left {
    position: static;
    width: 100%;
  }
  .bubble {
    padding: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .bubble {
    font-size: 18px;
  }
  .text-wrapper span {
    max-width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .bubble {
    font-size: 11px;
    padding: 15px 18px;
  }

  .bubble-helper {
    left: -225px;
  }
}
